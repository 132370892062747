import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
    messageBox: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 35,
    },
    messageContent: {
        maxWidth: '70%',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: '400',
    },
});

export default function Title({ title, subtitle, subtitleArray, sxTitle, sxSubtitle, subtitlebold }) {
    const classes = useStyles();
    return (
        <Grid container spacing={3} className={classes.messageBox}>
            <Grid item xs={12} sx={{ textAlign: 'center' }} mt={5} className={classes.messageContent}>
                {title && (
                    <Typography variant='h3' component='h2' color='primary.light' sx={sxTitle} mt={1}>
                        {title}
                    </Typography>
                )}
                {subtitle && (
                    <Typography variant='h6' component='h3' color='primary.subtitle' fontFamily="BentonSans_Book" mt={1}
                        mb={2} sx={sxSubtitle}>
                        {subtitle}
                    </Typography>
                )}
                {subtitlebold && (
                    <Typography variant='h6' component='h3' color='primary.subtitle' fontFamily="BentonSans_Bold" fontWeight={800} mt={1}
                        mb={2} sx={sxSubtitle}>
                        {subtitlebold}
                    </Typography>
                )}
                {subtitleArray &&
                    subtitleArray.map((subtitleElement, index) => (
                        <Typography key={`${index}-subtitle`}
                            variant='h6' component='h3'
                            color='primary.subtitle'
                            fontFamily="BentonSans_Book" sx={sxSubtitle}>
                            {subtitleElement}
                        </Typography>
                    ))}
            </Grid>
        </Grid>
    );
}
