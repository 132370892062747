import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import cardAllocationSlice from '../cards/allocateCard/slice';
import logger from 'redux-logger';
import newsSlice from '../news/slice';
import orderCardSlice from '../cards/orderCard/redux/slice';
import contactSlice from '../contactUs/slice';
import loginSlice from '../login/slice';
import faqSlice from '../faqs/slice';
import noticeSlice from '../notice/slice';
import privacyStatementSlice from '../home/privacyStatement/slice'
import headerSlice from '../common/components/Header/slice';
import conditionSlice from '../home/conditions/slice';
import dashboardSlice from '../dashboard/slice';
import getPayCardSlice from '../home/getPayCard/slice';
import givePayCardSlice from '../home/givePayCard/slice';
import depositFundsSlice from '../funds/depositFunds/slice';
import loadFundSlice from '../funds/loadFunds/slice';
import cancelPendingLoadSlice from '../funds/cancelPendingLoad/redux/slice'
import profileHistorySlice from '../reports/profileHistory/slice';
import manageCardSlice from '../cards/manageCard/slice';
import downloadCardTransactionsSlice from '../reports/donwloadCardTransactions/redux/slice'
import cardStatementSlice from '../reports/cardStatement/slice';
import dataTableSlice from '../common/components/base/datatable/slice';
import reportCardListSlice from '../reports/cardList/slice';
import downloadPreviousInvoiceSlice from '../reports/donwloadPreviousInvoice/redux/slice';
import scheduledLoadSlice from '../funds/scheduledLoads/slice';
import batchLoadSlice from '../funds/batchLoad/slice';
import currentBalanceOfCardsSlice from '../reports/currentBalanceOfCards/slice';
import updateEmailSlice from '../setting/updateEmail/slice';
import updatePasswordSlice from '../setting/updatePassword/slice';
import registrationDetailSlice from '../setting/RegistrationDetails/redux/slice';
import  termsSlice  from '../home/termsAndCondition/slice';

const appReducer = combineReducers({
    login: loginSlice,
    news: newsSlice,
    notices: noticeSlice,
    cardAllocation: cardAllocationSlice,
    orderCard: orderCardSlice,
    contact: contactSlice,
    faqs: faqSlice,
    privacyStatement: privacyStatementSlice,
    header: headerSlice,
    conditions: conditionSlice,
    dashboard: dashboardSlice,
    getPayCard: getPayCardSlice,
    givePayCard: givePayCardSlice,
    depositFunds: depositFundsSlice,
    loadFunds: loadFundSlice,
    cancelPendingLoad: cancelPendingLoadSlice,
    profileHistory: profileHistorySlice,
    manageCard: manageCardSlice,
    downloadCardTransactions: downloadCardTransactionsSlice,
    cardStatement: cardStatementSlice,
    dataTable: dataTableSlice,
    reportCardList: reportCardListSlice,
    downloadPreviousInvoice: downloadPreviousInvoiceSlice,
    scheduledLoads: scheduledLoadSlice,
    batchLoad: batchLoadSlice,
    currentBalance: currentBalanceOfCardsSlice,
    updateEmail: updateEmailSlice,
    updatePassword: updatePasswordSlice,
    settingAccount: registrationDetailSlice,
    termsAndConditions:termsSlice

})

const rootReducer = (state, action) => {
    if (action.type === "login/verifyLogout") {
        state = {};
    }
    return appReducer(state, action);
}
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => process.env.NODE_ENV !== 'production' ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
