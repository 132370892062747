import { createSlice } from '@reduxjs/toolkit';
import ReduxStatus from '../../../common/constants/ReduxStatus';
import { searchInvoices, fetchCardLoadPdf, fetchCardOrderPdf } from './thunk';

const initialState = {
    invoices: {
        data: {},
        filter: {},
        errorMessages: '',
        status: ReduxStatus.empty,
    },
    pdf: {
        data: {},
        errorMessages: '',
        donwloaded: false,
        status: ReduxStatus.empty,
    },
};

export const downloadPreviousInvoiceSlice = createSlice({
    name: 'downloadPreviousInvoice',
    initialState,
    reducers: {
        saveFilter: (state, action) => {
            state.cards.filter = action.payload;
        },
    },
    extraReducers: {
        [searchInvoices.pending]: (state, action) => {
            state.invoices.status = ReduxStatus.loading;
        },
        [searchInvoices.fulfilled]: (state, action) => {
            state.invoices.status = ReduxStatus.loaded;
            state.invoices.data = action.payload;
            state.invoices.filter = action.meta.arg;
            if (action.payload.INVOICES.length === 0) {
                state.invoices.errorMessages = 'No results found.';
            } else {
                state.invoices.errorMessages = '';
            }
        },
        [searchInvoices.rejected]: (state, action) => {
            state.invoices.errorMessages = 'Could not load the list of invoices from the server. Please try again later...';
            state.invoices.status = ReduxStatus.loadFailed;
        },
        [fetchCardLoadPdf.pending]: (state, action) => {
            state.pdf.status = ReduxStatus.loading;
        },
        [fetchCardLoadPdf.fulfilled]: (state, action) => {
            state.pdf.data = action.payload;
            state.pdf.status = ReduxStatus.loaded;
            state.pdf.shouldDownload = true;
        },
        [fetchCardLoadPdf.rejected]: (state, action) => {
            state.pdf.errorMessages = 'Could not load the invoice from the server. Please try again later...';
            state.pdf.status = ReduxStatus.loadFailed;
        },
        [fetchCardOrderPdf.pending]: (state, action) => {
            state.pdf.status = ReduxStatus.loading;
        },
        [fetchCardOrderPdf.fulfilled]: (state, action) => {
            state.pdf.data = action.payload;
            state.pdf.status = ReduxStatus.loaded;
            state.pdf.shouldDownload = true;
        },
        [fetchCardOrderPdf.rejected]: (state, action) => {
            state.pdf.errorMessages = 'Could not load the invoice from the server. Please try again later...';
            state.pdf.status = ReduxStatus.loadFailed;
        },
    },
});

export const { saveFilter } = downloadPreviousInvoiceSlice.actions;

export default downloadPreviousInvoiceSlice.reducer;
