import React from 'react';
import Dialog from '../../common/components/base/dialog/Dialog';
import { closeDialog } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from './LoginForm';
import PasswordReminderForm from './PasswordReminderForm';

export default function Login() {
    const dispatch = useDispatch();
    const open = useSelector(state => state.login.open);
    const loginPageState = useSelector(state => state.login.loginPageState);

    const handleClose = () => {
        dispatch(closeDialog());
    }

    const handleOk = () => {
        dispatch(closeDialog());
    }

    return (
        <Dialog
            data-testid="login-component"
            open={open}
            dialogTitle={loginPageState === "login" ? "PAYCARD LOGIN" : "FORGOT PASSWORD"}
            dialogTitleTextAlign="center"
            cancelButtonText="Cancel"
            okButtonText="Ok"
            onClose={handleClose}
            handleOk={handleOk}
            showDialogTitle={true}
            showDialogActions={false}
            scroll="paper"
        >
            {loginPageState === "login" && <LoginForm />}
            {loginPageState === "passwordReminder" && <PasswordReminderForm />}
        </Dialog>
    );
}
