import { Box, Card, Container, Grid, Typography, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import Title from '../../common/components/base/title/Title';
import themeConstants from '../../common/constants/Theme';
import DatePicker from '../../common/components/base/pickers/DatePicker';
import Text from '../../common/components/base/text';
import Button from '../../common/components/base/button/Button';
import AllocatedCards from './allocatedCard';
import UnAllocatedCards from './unAllocatedCard';
import { reportCardListActions } from './slice';
import { fetchAllocatedCards, fetchCardsDocumentXLS, fetchCardsDocumentPDF } from './thunk';
import Loader from '../../common/components/base/loader/Loader';
import ReduxStatus from '../../common/constants/ReduxStatus';
import { downloadData } from '../profileHistory/common/utils';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
        fontSize: '14px',
        paddingBottom: 80,
    },
    orangeCard: {
        backgroundImage: `linear-gradient(to right, ${themeConstants.ORANGE_DARK}, ${themeConstants.ORANGE_LIGHT}) !important`,
        padding: '40px 30px !important',
        boxShadow: 'none !important',
        '& p': {
            color: theme.palette.secondary.light,
        },
    },
}));

const CardList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const status = useSelector(state => state.reportCardList.allocatedCards.status);
    const open = status === ReduxStatus.loading ? true : false;

    const statuspdf = useSelector(state => state.reportCardList.pdf.status)
    const openpdf = statuspdf === ReduxStatus.loading ? true : false;

    const allocatedCards = useSelector((state) => state.reportCardList.allocatedCards);
    const filter = useSelector((state) => state.reportCardList.allocatedCards.filter);

    const allocatedTotal = useSelector((state) => state.reportCardList.allocatedCards.data);
    const unallocatedTotal = useSelector((state) => state.reportCardList.unAllocatedCards.data);

    const docDownload = useSelector((state) => state.reportCardList.docDownload);

    const { wholeView, pdf } = useSelector((state) => state.reportCardList);

    const [errorMsg, setErrorMsg] = useState('');
    
    const [stateDate, setStateDate] = useState({
        startDate: DateTime.local().minus({ months: 3 }).toFormat('yyyy-MM-dd'),
        endDate: DateTime.now().toFormat('yyyy-MM-dd'),

    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        let payload = {
            ...allocatedCards.filter,
            [name]: DateTime.fromJSDate(value).toFormat('yyyy-MM-dd'),
        };
        dispatch(reportCardListActions.cardFilterChanged(payload));
        setErrorMsg('')
    };

    const validateDate = (startDate, endDate) => {
        let d1 = DateTime.fromISO(startDate);
        let d2 = DateTime.fromISO(endDate);
        let result = d2.diff(d1, ['months']).toObject();
        if (result.months > 3) {
            return true;
        } else {
            return false;
        }
    };

    const handleFilter = (event) => {
        event.preventDefault();
        if (validateDate(filter.startDate, filter.endDate)) {
            setErrorMsg('The date range cannot exceed 3 months');

        } else {
            dispatch(fetchAllocatedCards(filter));
            setStateDate({
                ...stateDate,
                startDate: filter.startDate,
                endDate: filter.endDate
            })
        }
    };

    const handleDownload = () => {
        dispatch(fetchCardsDocumentXLS(allocatedCards.filter));
        dispatch(reportCardListActions.cardsDownloadRequested(true));
    };

    useEffect(() => {
        if (docDownload.data && docDownload.shouldDownload && !docDownload.downloaded) {
            dispatch(reportCardListActions.cardsDownloadStatusUpdated(true));
            dispatch(reportCardListActions.cardsDownloadRequested(false));
            downloadData(docDownload.data, `${allocatedCards.filter.startDate} - ${allocatedCards.filter.endDate}.xls`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docDownload]);

    const handleViewChange = (view) => {
        dispatch(fetchCardsDocumentPDF(allocatedCards.filter));
        dispatch(reportCardListActions.cardsPDFViewChanged(view));
    };

    return (
        <div className={classes.mainbg}>
            <Loader open={open} />
            <Loader open={openpdf} />
            <Container maxWidth='lg'>
                <Title title={'View a list of cards'} subtitle={'Cards linked to your profile.'}></Title>
                <Card sx={{ mt: 7 }} className={classes.orangeCard}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant='h4' color='secondary.light'>
                                Card Details
                            </Typography>
                            <Typography
                                variant='subtitle2'
                                color='secondary.light'
                                fontFamily='BentonSans_Regular'
                                mt={2}
                            >
                                You have allocated {allocatedTotal.length} cards. {unallocatedTotal.length} cards have
                                not yet been allocated. Cards must be allocated before they can be used.
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <img
                                src={require('../../assets/images/img_content_depositFunds.png')}
                                alt=""
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    maxWidth: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Card>
                {wholeView.view === 'list' ? (
                    <>
                        <Typography
                            variant='subtitle1'
                            sx={{ color: 'primary.light', fontSize: 20, fontWeight: 400 }}
                            mt={8}
                            mb={1}
                            align={'center'}
                        >
                            Viewing allocated cards from {stateDate.startDate} to {stateDate.endDate}
                        </Typography>
                        <Typography
                            variant='h6'
                            sx={{ color: 'primary.light', fontSize: 20, fontWeight: 400 }}
                            mt={6}
                            mb={2}
                        >
                            FILTER ALLOCATED CARD DATES
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6}>
                                <Text light={true} mb={-2}>
                                    START DATE
                                </Text>
                                <DatePicker
                                    onChange={(e) => handleChange(e)}
                                    value={new Date(filter.startDate)}
                                    fullWidth
                                    name='startDate'
                                    id='startDate'
                                    views={['year', 'month', 'day']}
                                    format='DD-MM-YYYY'
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Text light={true} mb={-2}>
                                    END DATE
                                </Text>
                                <DatePicker
                                    onChange={(e) => handleChange(e)}
                                    value={new Date(filter.endDate)}
                                    fullWidth
                                    name='endDate'
                                    views={['year', 'month', 'day']}
                                    id='endDate'
                                    format='DD-MM-YYYY'
                                />
                            </Grid>
                            {errorMsg && (
                                <Grid item xs={12}>
                                    <Alert severity='error'>
                                        {errorMsg}
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                        <Button
                            onClick={(event) => handleFilter(event)}
                            variant='contained'
                            size='large'
                            styles={{ margin: '40px 0px 0px' }}
                        >
                            FILTER
                        </Button>
                        <AllocatedCards />
                        <UnAllocatedCards />
                    </>
                ) : (
                    <Box mt={10}>
                        {pdf.data ? (
                            <embed
                                src={pdf.data}
                                id='CardList'
                                alt='Card List'
                                title='Card List'
                                width='100%'
                                height='600px'
                                style={{ borderStyle: 'solid', borderWidth: '2px' }}
                                type='application/pdf'
                            />
                        ) : (
                            <Text light={true} sx={{ textAlign: 'center' }} mt={10}>
                                No Document data available
                            </Text>
                        )}
                    </Box>
                )}
                <Box sx={{ mt: 6, display: 'flex' }}>
                    <Box>
                        <Button onClick={handleDownload} color='secondary' variant='contained'>
                            EXCEL DOWNLOAD
                        </Button>
                    </Box>
                    {wholeView.view === 'list' ? (
                        <Box sx={{ ml: 3 }}>
                            <Button color='secondary' variant='outlined' onClick={() => handleViewChange('document')}>
                                PRINTABLE VERSION
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{ ml: 3 }}>
                            <Button color='secondary' variant='outlined' onClick={() => handleViewChange('list')}>
                                NORMAL VIEW
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>
        </div>
    );
};

export default CardList;
