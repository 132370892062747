import { createAsyncThunk } from '@reduxjs/toolkit';
import { jwtLogin, jwtReminder, jwtResetPw, getMultipleAccounts, doSelectAccounts } from './service';

export const verifyLogin = createAsyncThunk(
    'login/verify_login',
    async (params, { rejectWithValue }) => {
        try {
            const response = await jwtLogin(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const passwordReminder = createAsyncThunk(
    'login/password_reminder',
    async (params, { rejectWithValue }) => {
        try {
            const response = await jwtReminder(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const resetPassword = createAsyncThunk(
    'login/reset_password',
    async (params, { rejectWithValue }) => {
        try {
            const response = await jwtResetPw(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const getAccounts = createAsyncThunk(
    'login/get_multiple_accounts',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getMultipleAccounts();
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const doSelectAccount = createAsyncThunk(
    'login/do_select_profile',
    async (params, { rejectWithValue }) => {
        try {
            const response = await doSelectAccounts(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)
