import React, { useState } from 'react';
import {
    Grid
} from '@mui/material';
import { REGEX_EMAIL } from '../../common/utils/regex';
import Input from '../../common/components/base/input/Input';
import Button from '../../common/components/base/button/Button';
import { verifyRequestEmailChange } from './thunk';
import { useDispatch } from 'react-redux';

const mystyle = {
    margin: "0 auto",
    width: "80%"
};

const UpdateEmailRequestForm = () => {
    const [stateValues, setStateValues] = useState({
        accountEmail: '',
    });

    const [validationErrors, setValidationErrors] = useState({
        accountEmail: ''
    });
    const dispatch = useDispatch();

    const handleInput = input => event => {
        let value = event.target.value;
        if (input === "accountEmail" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountEmail: "email is required." }));
        }
        if (input === "accountEmail" && value.length !== 0) {
            if (REGEX_EMAIL.test(value))
                setValidationErrors(validationErrors => ({ ...validationErrors, accountEmail: '' }));
            else
                setValidationErrors(validationErrors => ({ ...validationErrors, accountEmail: 'Invalid email.' }));
        }
        setStateValues(prevStateValues => ({ ...prevStateValues, [input]: value }));
    };

    const sendRequest = () => {
        let errorCount = 0;
        if (stateValues.accountEmail.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, accountEmail: "email is required." }));
        }
        if (stateValues.accountEmail.length !== 0 && !REGEX_EMAIL.test(stateValues.accountEmail)) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, accountEmail: 'Invalid email.' }));
        }
        if (errorCount === 0) {
            dispatch(verifyRequestEmailChange(stateValues));
        }
    }

    return (
        <Grid container spacing={1} style={mystyle}>
            <Grid item xs={12} m={0} p={0}>
                <Input
                    inputLabel='EMAIL ADDRESS'
                    name='accountEmail'
                    value={stateValues.accountEmail}
                    type='email'
                    fullWidth
                    onChange={handleInput("accountEmail")}
                    error={validationErrors.accountEmail ? true : false}
                    helperText={validationErrors.accountEmail}
                />
                <Button
                    size='medium'
                    variant='contained'
                    styles={{ margin: '40px 20px 0px 0px' }}
                    onClick={sendRequest}
                >
                    SEND REQUEST
                </Button>
            </Grid>
        </Grid>
    );
}

export default UpdateEmailRequestForm;
