import React, { useEffect } from 'react';
import { getTerms } from './thunk';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../common/components/base/title/Title';
import { Typography, Container } from '@mui/material';
import ReactHtmlParser from 'html-react-parser';

const TermsAndConditions= () => {
    const terms = useSelector(state => state.termsAndConditions.data.content);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getTerms());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Title title='Terms and Conditions'></Title>
            <Container maxWidth='lg'>
                {
                    terms && terms.map((terms, idx) => (
                        <div key={terms.CONTENTID}>
                            <Typography color='primary.light' variant='h6' fontFamily='BentonSans_Regular' >{terms.CONTENTHEADER}</Typography>
                            <Typography color='secondary.dark' variant='subtitle2' fontFamily='BentonSans_Regular'>{ReactHtmlParser(terms.CONTENTDETAIL)}</Typography><br /><br />
                        </div>
                    ))
                }
            </Container>
        </div>
    );
};

export default TermsAndConditions;
