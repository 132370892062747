import React, { useState } from 'react';

import { Grid, Box, RadioGroup, FormControlLabel, Alert } from '@mui/material/';
import CheckRadio from '../../../common/components/base/checkradio/CheckRadio';
import Text from '../../../common/components/base/text/Text';
import DatePicker from '../../../common/components/base/pickers/DatePicker';
import Button from '../../../common/components/base/button/Button';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { searchInvoices } from '../redux/thunk';

const startDate = new Date();
const endDate = new Date();
const offset = 0;
const limit = 10;

const filterTopics = [
    {
        name: 'Card order invoice',
        id: 'cardOrder',
    },
    {
        name: 'Card load invoice',
        id: 'cardLoad',
    },
];

const SearchPreviousInvoices = () => {
    const dispatch = useDispatch();
    const [stateValues, setStateValues] = useState({
        searchField: 'cardOrder',
        startDate,
        endDate,
        errorMsg: '',
    });

    const handleTopicChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setStateValues((prevStateValues) => ({ ...prevStateValues, searchField: value }));
        }
    };

    const validateDate = (startDate, endDate) => {
        if (startDate - endDate > 0) {
            return false;
        } else {
            return true;
        }
    };

    const handleSearch = () => {
        if (validateDate(stateValues.startDate, stateValues.endDate)) {
            setStateValues({ ...stateValues, errorMsg: '' });
            const queryString = {
                srchtype: stateValues.searchField,
                startDate: DateTime.fromJSDate(stateValues.startDate).toISODate(),
                endDate: DateTime.fromJSDate(stateValues.endDate).toISODate(),
                offset,
                limit,
            };
            dispatch(searchInvoices(queryString));
        } else {
            setStateValues({ ...stateValues, errorMsg: 'The start date cannot be greater than the end date.' });
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', mt: 2 }}>
                    <Box>
                        <RadioGroup row onChange={handleTopicChange} name='searchField'>
                            {filterTopics.map((topic) => (
                                <FormControlLabel
                                    key={topic.id + '-filter-topic'}
                                    value={topic.id}
                                    control={
                                        <CheckRadio color='secondary' checked={stateValues?.searchField === topic.id} />
                                    }
                                    label={topic.name}
                                />
                            ))}
                        </RadioGroup>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{ pr: 1, mr: 1, mt: 2 }}>
                    <Text light sx={{ mb: -2 }}>
                        START DATE
                    </Text>
                    <DatePicker
                        fullWidth
                        value={stateValues.startDate}
                        name='startDate'
                        onValueChange={(date) => setStateValues({ ...stateValues, startDate: date })}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{ mt: 2 }}>
                    <Text light sx={{ mb: -2 }}>
                        END DATE
                    </Text>
                    <DatePicker
                        fullWidth
                        value={stateValues.endDate}
                        name='endDate'
                        onValueChange={(date) => setStateValues({ ...stateValues, endDate: date })}
                    />
                </Box>
            </Grid>
            {stateValues.errorMsg && (
                <Grid item xs={12}>
                    <Alert severity='error' sx={{ mt: 5 }}>
                        {stateValues.errorMsg}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <Button
                    onClick={handleSearch}
                    size='medium'
                    variant='contained'
                    styles={{ margin: '40px 20px 0px 0px' }}
                >
                    SEARCH
                </Button>
            </Grid>
        </Grid>
    );
};

export default SearchPreviousInvoices;
