import React, { useEffect, useRef } from 'react';
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { openDialog, verifyLogout, getAuthState } from '../../../login/slice';
import LoginPage from '../../../login';
import { dashboardUserHeader } from './thunk';
import { formatCurrency } from '../../utils/helper';
import { theme } from '../base/theme/ThemeProvider';
import Notices from '../../../notice';
import { PayCardIconUser } from '../icons';
import { CheckPathnameHome } from './pathname';
import { requestUpdateBalance } from './slice';

const useStyles = makeStyles({
    rightToolbar: {
        paddingRight: 20,
    },
    menuButton: {
        paddingLeft: 56,
        paddingRight: 23
    },
    menuText: {
        flex: 1,
        textAlign: 'center',
        padding: 0
    },
    logo: {
        maxHeight: 56,
        marginRight: 20,
        position: 'relative',
        top: -1.2
    },
    loginbg: {
        paddingLeft: 10
    },
    titleTextMain: {
        paddingRight: 30,
    },
    dvLogInOut: {
        position: 'relative',
        height: 80,
        width: 160,
        top: 0,
        background: theme.palette.secondary.main,
        cursor: 'pointer',
        textAlign: 'center',
        lineHeight: "80px"
    },
    logintext: {
        margin: 20
    },
    button: {
        textTransform: 'none !important',
        '&:hover': {
            backgroundColor: 'transparent !important',
        }
    }
});

// Update balance if not changing screen every 5 minutes
const BALANCE_UPDATE_INTERVAL = 5 * 60000;

const Header = () => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const isAuthenticated = useSelector(state => state.login.isAuthenticated);

    const headerEnable = useSelector(state => state.login.headerEnable);
    const accountSelected = JSON.parse(localStorage.getItem('account_selected'));
   
    const userHeader = useSelector(state => state.header.userHeader);
    const needUpdateBalance = useSelector(state => state.header.needUpdateBalance);

    const balanceUpdateIntervalRef = useRef();

    const { getState } = useStore();

    const location = useLocation();
    let path = location.pathname;
    let pathOutside = location.pathname.split('d/')[0] + "d";

    const openLoginFormDialog = () => {
        dispatch(openDialog());
    };

    useEffect(() => {
        dispatch(getAuthState());
        if (getState().login.isAuthenticated) {
            dispatch(dashboardUserHeader());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    useEffect(() => {
        if (needUpdateBalance) {
            dispatch(dashboardUserHeader());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needUpdateBalance]);

    useEffect(() => {
        balanceUpdateIntervalRef.current = setInterval(() => {
            if (getState().login.isAuthenticated) {
                dispatch(requestUpdateBalance(true));
            }
        }, BALANCE_UPDATE_INTERVAL);

        return () => {
            clearInterval(balanceUpdateIntervalRef.current);
        };
    }, []);

    useEffect(() => {
        if (getState().login.isLoggedOut) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const logout = () => {
        dispatch(verifyLogout());
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <section className={classes.menuButton}>
                        <Button className={classes.button} aria-label="Menu" color="inherit" component={Link} to="/">
                            <img src={require('../../../assets/images/standard_bank_logo.png')} alt="Standard Bank" className={classes.logo} />
                            <Typography variant="h6" color="inherit" fontFamily='BentonSans_Regular' >
                                PayCard
                            </Typography>
                        </Button>
                    </section>
                    {!isAuthenticated ?
                        <>
                            <section className={classes.menuText}>
                                <Grid container spacing={0} maxWidth={400} margin={'auto'}>
                                    <Grid item xs>
                                        <Button className={classes.button} component={Link} to="/" color="inherit">
                                            <Typography variant="subtitle1" fontWeight={CheckPathnameHome} fontFamily='BentonSans'>
                                                Home
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs>
                                        <Button className={classes.button} component={Link} to="/faqs" color="inherit" >
                                            <Typography variant="subtitle1" fontWeight={location.pathname === "/faqs" ? 500 : 100} fontFamily='BentonSans'>
                                                FAQ
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs>
                                        <Button className={classes.button} component={Link} to="/contactus" color="inherit" >
                                            <Typography variant="subtitle1" fontWeight={location.pathname === "/contactus" ? 500 : 100} fontFamily='BentonSans'>
                                                Contact Us
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </section>
                            <section className={classes.rightToolbar}>
                                <div className={classes.dvLogInOut}>
                                    <Button className={classes.button} data-testid="login-button" color="inherit" startIcon={<PayCardIconUser />} aria-label="Edit" onClick={openLoginFormDialog}>
                                        <Typography variant="subtitle2" color="inherit" className={classes.loginbg} fontFamily='BentonSans_Regular'>
                                            LOG IN
                                        </Typography>
                                    </Button>
                                </div>
                            </section></> :
                        <>
                            <section className={classes.menuText}>
                                <Grid container spacing={0} maxWidth={600} margin={'auto'}>
                                    <Grid item xs={1}>
                                        <Button className={classes.button} component={Link} to="/news" color="inherit" >
                                            <Typography variant="subtitle1" fontWeight={location.pathname === "/news" ? 500 : 100} fontFamily='BentonSans'>
                                                News
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={5}>
                                        {headerEnable || accountSelected ?
                                            <Button className={classes.button} component={Link} to="/dashboard" color="inherit" >
                                                <Typography variant="subtitle1" fontWeight={(path === "/dashboard" || pathOutside === "/dashboard") ? 500 : 100} fontFamily='BentonSans'>
                                                    {userHeader && userHeader.FULLNAME}
                                                </Typography>
                                            </Button> :
                                            <Button className={classes.button} color="inherit" >
                                                <Typography variant="subtitle1" fontWeight={(path === "/dashboard" || pathOutside === "/dashboard") ? 500 : 100} fontFamily='BentonSans'>
                                                    {userHeader && userHeader.FULLNAME}
                                                </Typography>
                                            </Button>}
                                    </Grid>
                                    <Grid item >
                                        <Button className={classes.button} color="inherit">
                                            <Typography variant="subtitle1" color="inherit" fontFamily='BentonSans_Light' style={{ marginRight: '5px' }}>
                                                Your balance is
                                            </Typography>
                                            <Typography variant="subtitle1" color="inherit" fontWeight={400}>{headerEnable || accountSelected ? +" " + userHeader && formatCurrency(+userHeader.BALANCEACCOUNT) : "R0.00"}</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </section>
                            <section className={classes.rightToolbar}>
                                <div className={classes.dvLogInOut}>
                                    <Button className={classes.button} data-testid="logout-button" color="inherit" startIcon={<PayCardIconUser />} aria-label="Edit" onClick={logout}>
                                        <Typography variant="subtitle2" color="inherit" className={classes.loginbg} fontFamily='BentonSans_Regular'>
                                            LOG OUT
                                        </Typography>
                                    </Button>
                                </div>
                            </section>
                            <Notices />

                        </>}
                </Toolbar>
            </AppBar>
            <LoginPage />
        </>
    );
}

export default Header;
