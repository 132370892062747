import { createSlice } from '@reduxjs/toolkit';
import { verifyLogin, passwordReminder, resetPassword, getAccounts, doSelectAccount } from './thunk';
import ReduxStatus from '../common/constants/ReduxStatus';

const initialState = {
    open: false,
    loginPageState: 'login',
    isAuthenticated: false,
    status: ReduxStatus.empty,
    errorMessages: {},
    isLoggedOut: false,
    impersonateData: [],
    headerEnable: true,
    accountSelect:false,
    statusProfile: ReduxStatus.empty
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        openDialog: (state, action) => {
            state.open = true;
            state.loginPageState = "login";
        },
        closeDialog: (state, action) => {
            state.open = false;
        },
        renderPasswordReminderDialog: (state, action) => {
            state.loginPageState = "passwordReminder";
        },
        getAuthState: (state, action) => {
            let token = localStorage.getItem('access_token');
            state.isAuthenticated = !!token;
        },
        verifyLogout: (state, action) => {
            localStorage.clear();
            state.isAuthenticated = false;
            state.isLoggedOut = true;


        }
    },
    extraReducers: {
        [verifyLogin.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
            state.isAuthenticated = false;
        },
        [verifyLogin.fulfilled]: (state, action) => {
            localStorage.setItem('access_token_no_refresh', action.payload.jwtToken);
            localStorage.setItem('access_token', action.payload.jwtToken);
            localStorage.setItem('refresh_token', action.payload.refreshToken);
            localStorage.setItem('multiple_accounts',JSON.stringify(action.payload.multipleAccounts));
            localStorage.setItem('account_selected', JSON.stringify(state.accountSelect));
            state.status = ReduxStatus.loaded;
            state.isAuthenticated = true;
            state.isLoggedOut = false;
        },
        [verifyLogin.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
            state.isAuthenticated = false;
        },
        [passwordReminder.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [passwordReminder.fulfilled]: (state, action) => {
            state.status = ReduxStatus.loaded;
        },
        [passwordReminder.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        [resetPassword.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [resetPassword.fulfilled]: (state, action) => {
            state.status = ReduxStatus.loaded;
        },
        [resetPassword.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        [getAccounts.pending]: (state, action) => {
            state.statusProfile = ReduxStatus.loading;
        },
        [getAccounts.fulfilled]: (state, action) => {
            state.statusProfile = ReduxStatus.loaded;
            state.impersonateData = action.payload.APPROVEDACCOUNTS;
            state.headerEnable = false;
        },
        [getAccounts.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.statusProfile = ReduxStatus.loadFailed;
        },
        [doSelectAccount.pending]: (state, action) => {
            state.statusProfile = ReduxStatus.loading;
        },
        [doSelectAccount.fulfilled]: (state, action) => {
            let accountChoose=true;
            localStorage.setItem('access_token_no_refresh', action.payload.jwtToken);
            localStorage.setItem('access_token', action.payload.jwtToken);
            localStorage.setItem('refresh_token', action.payload.refreshToken);
            localStorage.setItem('account_selected',JSON.stringify(accountChoose));
            localStorage.setItem('multiple_accounts',JSON.stringify(action.payload.multipleAccounts));
            state.statusProfile = ReduxStatus.loaded;
            state.headerEnable = true;
        },
        [doSelectAccount.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.statusProfile = ReduxStatus.loadFailed;
        },
    }
});

export const { openDialog, closeDialog, renderPasswordReminderDialog, getAuthState, verifyLogout } = loginSlice.actions;
export default loginSlice.reducer;
