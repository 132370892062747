import Http from "../common/utils/Http";

export const jwtLogin = async (params) => {
    try {
        let response = await Http.post('jwt/login', params);
        return response;
    } catch (error) {
        throw error;
    }
}

export const jwtReminder = async (params) => {
    try {
        let response = await Http.post('jwt/reminder', params);
        return response;
    } catch (error) {
        throw error;
    }
}

export const jwtResetPw = async (params) => {
    try {
        let response = await Http.post('jwt/resetpw', params);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getMultipleAccounts = async () => {
    try {
        let response = await Http.get('pr/select-profile/get-accounts');
        return response;
    } catch (error) {
        throw error;
    }
}

export const doSelectAccounts = async (params) => {
    try {
        let response = await Http.post('pr/select-profile/do-select-profile',params);
        return response;
    } catch (error) {
        throw error;
    }
}
