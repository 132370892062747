import React, { useEffect, useState } from 'react';
import Title from '../../../common/components/base/title/Title';
import { Container, Tooltip, Typography, Box, FormHelperText, Alert, Grid } from '@mui/material';
import Input from '../../../common/components/base/input/Input';
import Button from '../../../common/components/base/button/Button';
import ReduxStatus from "../../../common/constants/ReduxStatus";
import { useDispatch, useSelector } from 'react-redux';
import {
    verifyBearerInfo,
    verifyCardStop,
    getStopReason,
    verifyCardEnable,
    verifyCardRetire,
    getCardInfo,
} from '../thunk';
import useCustom from '../../../common/components/hooks/index';
import Loader from '../../../common/components/base/loader/Loader';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import * as validation from './validation';
import { useNavigate } from 'react-router-dom';
import {manageCardActions} from "../slice";
import {MAX_ID_LENGTH, MIN_ID_LENGTH, phoneNumberTip} from "./validation";

const useStyles = makeStyles({
    textBox: {
        marginBottom: '8px',
        marginTop: '22px',
    },
});

const EditManageCard = () => {
    const classes = useStyles();
    const { showAlert } = useCustom();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const accountId = localStorage.getItem("accountId")

    const info = useSelector((state) => state.manageCard.cardInfo.data);
    const errorMessages = useSelector((state) => state.manageCard.errorMessages);

    const maskedCard = localStorage.getItem("maskedcard")

    const reasons = useSelector((state) => state.manageCard.stopReasons.data.STOPREASONS);

    const status = useSelector((state) => state.manageCard.status);
    const open = status === 'loading' ? true : false;

    const [stateValues, setStateValues] = useState({
        accountCardFirstName: '',
        accountCardSurName: '',
        accountCardIdNumber: '',
        accountCardCellPhoneNumber: '',
        accountCardID: accountId,
        isCardRetired: '',
        isCardStopped: '',
    });
    const [stateValuesExtra, setStateValuesExtra] = useState({
        stopReasonID: '',
        accountCardID: '',
        accountCardBalance: '',
        accountCardExpiry: ''

    });

    const [stateValuesCard, setStateValuesCard] = useState({
        accountCardID: '',
    });

    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setStateValues({
            ...stateValues,
            accountCardFirstName: info.ACCOUNTCARDFIRSTNAME,
            accountCardSurName: info.ACCOUNTCARDSURNAME,
            accountCardIdNumber: info.ACCOUNTCARDIDNUMBER,
            accountCardCellPhoneNumber: info.ACCOUNTCARDCELLPHONE,
            accountCardID: accountId,
            isCardRetired: info.ISCARDRETIRED,
            isCardStopped: info.ISCARDSTOPPED,
        });
        setStateValuesExtra({
            ...stateValuesExtra,
            accountCardBalance: info.ACCOUNTCARDBALANCE,
            accountCardExpiry: info.ACCOUNTCARDEXPIRY,
            accountCardID: accountId,

        });
        setStateValuesCard({
            ...stateValuesCard,
            accountCardID: accountId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info]);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(manageCardActions.errorMessageChanged(""));
        dispatch(getCardInfo(accountId));
        dispatch(getStopReason());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSubmit && isValidated()) {
            dispatch(verifyBearerInfo(stateValues)).then((response) => {
                if (response.type === 'manageCard/verifyBearerInfo/fulfilled') {
                    showAlert('The card details have been updated.', 'info');
                    navigate('/dashboard/manage-card')
                } else {
                    showAlert('Something went wrong', 'error');
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmit]);


    const handleUserInput = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        if (name === "accountCardFirstName" && value.length !== 0) {
            setValidationErrors({ ...validationErrors, firstNameError: '' });
        }
        if (name === "accountCardSurName" && value.length !== 0) {
            setValidationErrors({ ...validationErrors, surNameError: '' });
        }
        if (name === "accountCardIdNumber" && value.length !== 0) {
            setValidationErrors({ ...validationErrors, idError: '' });
        }
        if (name === "accountCardCellPhoneNumber" && value.length !== 0) {
            setValidationErrors({ ...validationErrors, contactNumberError: '' });
        }
        setStateValues({ ...stateValues, [name]: value });
        setIsSubmit(false);
    };

    const handleUserInputCard = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setStateValuesExtra({ ...stateValuesExtra, [name]: value });

        if (!validation.isStopReasonError(value)) {
            setValidationErrors({
                ...validationErrors,
                stopReasonError: '',
            });
        }
    };

    const handleSubmitBearer = (e) => {
        e.preventDefault();
        setValidationErrors({});

        if (stateValues.accountCardFirstName.length === 0 && stateValues.accountCardSurName.length === 0 && stateValues.accountCardIdNumber.length === 0) {
            setValidationErrors({ ...validationErrors, firstNameError: "This is required.", surNameError: "This is required.", idError: "This is required." });
        }
        else if (stateValues.accountCardFirstName.length === 0) {
            setValidationErrors({ ...validationErrors, firstNameError: "This is required." });
        }
        else if (stateValues.accountCardSurName.length === 0) {
            setValidationErrors({ ...validationErrors, surNameError: "This is required." });

        }
        else if (stateValues.accountCardCellPhoneNumber.length === 0) {
            setValidationErrors({ ...validationErrors, contactNumberError: "This is required." });

        }
        else if (stateValues.accountCardIdNumber.length === 0) {
            setValidationErrors({ ...validationErrors, idError: "This is required." });
        }
        else if (validation.isIdError(stateValues.accountCardIdNumber)) {
            setValidationErrors({ ...validationErrors, idError: `Please fill in a valid ID whose length is between ${MIN_ID_LENGTH} and ${MAX_ID_LENGTH}` });
        }
        setIsSubmit(true);


    };

    const isValidated = () => {
        for (const key in validationErrors) {
            if (validationErrors[key] !== '') {
                return false;
            }
        }
        return true;
    }
    const handleSubmitCardStop = (e) => {
        e.preventDefault();
        let errorCount = 0;

        if (validation.isStopReasonError(stateValuesExtra.stopReasonID)) {
            setValidationErrors({
                ...validationErrors,
                stopReasonError: 'Please choose a stop reason.',
            });
            errorCount++;
        }

        if (errorCount === 0) {
            setValidationErrors({
                ...validationErrors,
                stopReasonError: '',
            });
            dispatch(verifyCardStop(stateValuesExtra)).then((response) => {
                if (response.type === 'manageCard/verifyCardStop/fulfilled') {
                    setStateValues({ ...stateValues, isCardStopped: !stateValues.isCardStopped });
                    navigate('/dashboard')
                } else {
                    showAlert('Something went wrong', 'error');
                }
            });
        }
    };

    const handleSubmitCardEnable = (e) => {
        e.preventDefault();
        dispatch(verifyCardEnable(stateValuesCard)).then((response) => {
            if (response.type === 'manageCard/verifyCardEnable/fulfilled') {
                setStateValues({ ...stateValues, isCardStopped: !stateValues.isCardStopped });
                navigate('/dashboard')
            } else {
                showAlert('Something went wrong', 'error');
            }
        });
    };

    const handleSubmitCardRetire = (e) => {
        e.preventDefault();
        dispatch(verifyCardRetire(stateValuesCard)).then((response) => {
            if (response.type === 'manageCard/verifyCardRetire/fulfilled') {
                setStateValues({ ...stateValues, isCardRetired: !stateValues.isCardRetired });
                navigate('/dashboard')
            } else {
                showAlert('Something went wrong', 'error');
            }
        });
    };

    return (
        <Container maxWidth='md'>
            <Title
                title='Card Holder Details'
                subtitle={'You can update card holder details for card or stop the card '}
                sxTitle={{ mb: 5 }}
                subtitlebold={maskedCard}
            />
            {stateValues.isCardRetired && (
                <Grid item xs={12} mb={2}>
                    <Alert severity='warning'>
                        This card is retired.
                    </Alert>
                </Grid>
            )}
            <Typography variant='h6' fontFamily='BentonSans_Regular' color='primary.light' gutterBottom>
                1. BEARER INFORMATION
            </Typography>
            <Loader open={open} />
            <form onSubmit={handleSubmitBearer}>
                <Input
                    name='accountCardFirstName'
                    id='accountCardFirstName'
                    fullWidth
                    value={stateValues.accountCardFirstName || ''}
                    inputLabel={<small style={{ fontSize: 11 }}>FIRST NAME *</small>}
                    size='small'
                    onChange={handleUserInput}
                    inputProps={{ maxLength: 30 }}
                    error={validationErrors.firstNameError ? true : false}
                    helperText={validationErrors.firstNameError}
                />

                <Input
                    name='accountCardSurName'
                    id='accountCardSurName'
                    fullWidth
                    value={stateValues.accountCardSurName || ''}
                    inputLabel={<small style={{ fontSize: 11 }}>SURNAME *</small>}
                    size='small'
                    onChange={handleUserInput}
                    inputProps={{ maxLength: 30 }}
                    error={validationErrors.surNameError ? true : false}
                    helperText={validationErrors.surNameError}
                />

                <Input
                    name='accountCardIdNumber'
                    id='accountCardIdNumber'
                    fullWidth
                    value={stateValues.accountCardIdNumber || ''}
                    inputLabel={<small style={{ fontSize: 11 }}>ID/PASSPORT NUMBER *</small>}
                    size='small'
                    onChange={handleUserInput}
                    inputProps={{ maxLength: 20 }}
                    error={validationErrors.idError ? true : false}
                    helperText={validationErrors.idError}
                />

                <Tooltip arrow title={phoneNumberTip}>
                    <Input
                        name='accountCardCellPhoneNumber'
                        id='accountCardCellPhoneNumber'
                        fullWidth
                        value={stateValues.accountCardCellPhoneNumber || ''}
                        inputLabel={<small style={{ fontSize: 11 }}>CELLPHONE NUMBER</small>}
                        size='small'
                        onChange={handleUserInput}
                        inputProps={{ maxLength: 20 }}
                        error={validationErrors.contactNumberError ? true : false}
                        helperText={validationErrors.contactNumberError}
                    />
                </Tooltip>



                {
                    status === ReduxStatus.loadFailed &&
                    errorMessages &&

                    <Box sx={{mt:2}}>
                        <Grid item xs={12} mb={2}>
                            <div data-testid="cardUpdateFicaErrorMessage" style={{color: "red"}}>
                                {errorMessages}
                            </div>
                        </Grid>
                    </Box>
                }

                <Button type='submit' data-testid="manageCardSaveBtn" value='submit' variant='contained' size='medium' styles={{ margin: '20px 0px 50px' }}>
                    SAVE
                </Button>
            </form>

            <Typography variant='h6' fontFamily='BentonSans_Regular' color='primary.light' gutterBottom>
                2. CARD INFORMATION
            </Typography>
            <form>
                <Input
                    name='accountCardBalance'
                    fullWidth
                    value={stateValuesExtra.accountCardBalance || ''}
                    inputLabel={<small style={{ fontSize: 11 }}>BALANCE *</small>}
                    size='small'
                    disabled
                />

                <Input
                    name='accountCardExpiry'
                    fullWidth
                    value={stateValuesExtra.accountCardExpiry || ''}
                    inputLabel={<small style={{ fontSize: 11 }}>EXPIRY DATE *</small>}
                    size='small'
                    disabled
                />



                {stateValues.isCardRetired === false ?
                    <>
                        <Box className={classes.textBox}>
                            <Typography color='primary.subtitle' variant='inputField'>
                                STOP REASON *
                            </Typography>
                            <FormControl fullWidth size='small' style={{ borderColor: '#ffffff', marginTop: 4 }}>
                                {reasons && (
                                    <>
                                        <Select
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            name='stopReasonID'
                                            value={stateValuesExtra.stopReasonID || ''}
                                            onChange={handleUserInputCard}
                                            sx={{ backgroundColor: '#FFF' }}
                                            error={validationErrors.stopReasonError ? true : false}
                                        >
                                            <MenuItem disabled value=''>
                                                Select stop reason
                                            </MenuItem>
                                            {reasons.map((reasons, index) => (
                                                <MenuItem key={index} value={reasons.STOPREASONID}>
                                                    {reasons.STOPREASONSHORT}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText sx={{ color: 'red' }}>
                                            {validationErrors.stopReasonError}
                                        </FormHelperText>
                                    </>
                                )}
                            </FormControl>
                        </Box>
                        {stateValues.isCardStopped === false ? (
                            <Button
                                onClick={handleSubmitCardStop}
                                variant='contained'
                                size='medium'
                                styles={{ margin: '20px 0px 50px' }}
                            >
                                Stop Card
                            </Button>
                        ) : (
                            <Button
                                onClick={handleSubmitCardEnable}
                                variant='contained'
                                size='medium'
                                styles={{ margin: '20px 0px 50px' }}
                            >
                                Enable Card
                            </Button>
                        )}

                        <Button
                            onClick={handleSubmitCardRetire}
                            variant='contained'
                            size='medium'
                            styles={{ margin: '20px 20px 50px' }}
                        >
                            Retire Card
                        </Button>

                    </> : <></>}




            </form>
        </Container>
    );
};

export default EditManageCard;
