import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    FormLabel,
    InputAdornment,
    IconButton,
    Box,
} from '@mui/material';
import Input from '../../common/components/base/input/Input';
import Button from '../../common/components/base/button/Button';
import { REGEX_PASSWORD } from '../../common/utils/regex';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { verifyUpdatePassword, verifyGetAccountEmail } from './thunk';
import { useDispatch, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifyLogout } from '../../login/slice';

const mystyle = {
    margin: "0 auto",
    width: "80%"
};

const UpdatePasswordForm = () => {
    const [stateValues, setStateValues] = useState({
        accountEmail: '',
        accountPasswordOld: '',
        accountPasswordNew: '',
        confirmNewPassword: '',
        updatePasswordComplete: false,
        isLoggedOut: false
    });

    const [validationErrors, setValidationErrors] = useState({
        accountPasswordOld: '',
        accountPasswordNew: '',
        confirmNewPassword: ''
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState({
        accountPasswordOld: false,
        accountPasswordNew: false,
        confirmNewPassword: false
    });

    useEffect(() => {
        dispatch(verifyGetAccountEmail())
            .then(response => {
                if (response.type === "verify/get_account_details/fulfilled") {
                    let accountEmail = response.payload.ACCOUNTEMAIL;
                    setStateValues(prevStateValues => ({ ...prevStateValues, accountEmail }));
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getState } = useStore();

    useEffect(() => {
        if (getState().login.isLoggedOut) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stateValues.updatePasswordComplete === true) {
            dispatch(verifyLogout());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateValues]);

    const handleInput = input => event => {
        let value = event.target.value;
        if (input === "accountPasswordOld" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountPasswordOld: "Old password is required." }));
        }
        if (input === "accountPasswordOld" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountPasswordOld: "" }));
        }
        if (input === "accountPasswordNew" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountPasswordNew: "New password is required." }));
        }
        if (input === "accountPasswordNew" && value.length !== 0) {
            if (REGEX_PASSWORD.test(value))
                setValidationErrors(validationErrors => ({ ...validationErrors, accountPasswordNew: '' }));
            else
                setValidationErrors(validationErrors => ({ ...validationErrors, accountPasswordNew: 'Password must contain at least 7 characters including one uppercase letter, number and special character.' }));
        }
        if (input === "confirmNewPassword" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, confirmNewPassword: "Confirm password is required." }));
        }
        if (input === "confirmNewPassword" && value.length !== 0) {
            if (REGEX_PASSWORD.test(value))
                setValidationErrors(validationErrors => ({ ...validationErrors, confirmNewPassword: '' }));
            else
                setValidationErrors(validationErrors => ({ ...validationErrors, confirmNewPassword: 'Password must contain at least 7 characters including one uppercase letter, number and special character.' }));
        }
        setStateValues(prevStateValues => ({ ...prevStateValues, [input]: value }));
    };

    const updatePassword = () => {
        let errorCount = 0;
        if (stateValues.accountPasswordOld.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, accountPasswordOld: "Old password is required." }));
        }
        if (stateValues.accountPasswordOld.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountPasswordOld: '' }));
        }
        if (stateValues.accountPasswordNew.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, accountPasswordNew: "New password is required." }));
        }
        if (stateValues.accountPasswordNew.length !== 0 && !REGEX_PASSWORD.test(stateValues.accountPasswordNew)) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, accountPasswordNew: 'Password must contain at least 7 characters including one uppercase letter, number and special character.' }));
        }
        if (stateValues.confirmNewPassword.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, confirmNewPassword: "Confirm password is required." }));
        }
        if (stateValues.confirmNewPassword.length !== 0 && !REGEX_PASSWORD.test(stateValues.confirmNewPassword)) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, confirmNewPassword: 'Password must contain at least 7 characters including one uppercase letter, number and special character.' }));
        }
        if (stateValues.accountPasswordNew.length !== 0 && stateValues.confirmNewPassword.length !== 0 && stateValues.accountPasswordNew !== stateValues.confirmNewPassword) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, confirmNewPassword: "Password and confirm password does not match." }));
        }
        if (errorCount === 0) {
            dispatch(verifyUpdatePassword({ "accountEmail": stateValues.accountEmail, "accountPasswordOld": stateValues.accountPasswordOld, "accountPasswordNew": stateValues.accountPasswordNew }))
                .then((response) => {
                    if (response.type === "verify/update_password/fulfilled") {
                        setStateValues(prevStateValues => ({ ...prevStateValues, updatePasswordComplete: true }));
                    }
                })
        }
    }

    return (
        <Grid container style={mystyle}>
            <Grid item xs={12} m={0} p={0}>
                <FormLabel
                    color='secondary'
                    id='delivery-method-group-label'
                    sx={{
                        color: 'primary.subtitle',
                    }}
                >
                    <Typography variant='h4' component='h4' sx={{
                        mt: 3, mb: 3
                    }}>*Required fields</Typography>
                </FormLabel>
                <Box>
                    <Typography variant='h4' component='h3' color='primary.light'>
                        LOGIN DETAILS
                    </Typography>
                </Box>
                <Input
                    inputLabel='EMAIL ADDRESS*'
                    name='accountEmail'
                    value={stateValues.accountEmail}
                    type='email'
                    onChange={handleInput("accountEmail")}
                    fullWidth
                    disabled
                />
                <Input
                    inputLabel='CURRENT PASSWORD*'
                    name='accountPasswordOld'
                    value={stateValues.accountPasswordOld}
                    type={showPassword.accountPasswordOld ? "text" : "password"}
                    onChange={handleInput("accountPasswordOld")}
                    fullWidth
                    required
                    error={validationErrors.accountPasswordOld ? true : false}
                    helperText={validationErrors.accountPasswordOld}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(prevShowPassword => ({ ...prevShowPassword, accountPasswordOld: !showPassword.accountPasswordOld }))}
                                    edge="end"
                                >
                                    {showPassword.accountPasswordOld ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Input
                    inputLabel='NEW PASSWORD*'
                    name='newPassword'
                    value={stateValues.accountPasswordNew}
                    type={showPassword.accountPasswordNew ? "text" : "password"}
                    onChange={handleInput("accountPasswordNew")}
                    fullWidth
                    required
                    error={validationErrors.accountPasswordNew ? true : false}
                    helperText={validationErrors.accountPasswordNew}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(prevShowPassword => ({ ...prevShowPassword, accountPasswordNew: !showPassword.accountPasswordNew }))}
                                    edge="end"
                                >
                                    {showPassword.accountPasswordNew ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Input
                    inputLabel='CONFIRM NEW PASSWORD*'
                    name='confirmNewPassword'
                    value={stateValues.confirmNewPassword}
                    type={showPassword.confirmNewPassword ? "text" : "password"}
                    onChange={handleInput("confirmNewPassword")}
                    fullWidth
                    required
                    error={validationErrors.confirmNewPassword ? true : false}
                    helperText={validationErrors.confirmNewPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(prevShowPassword => ({ ...prevShowPassword, confirmNewPassword: !showPassword.confirmNewPassword }))}
                                    edge="end"
                                >
                                    {showPassword.confirmNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} >
                <Button
                    size='medium'
                    variant='contained'
                    styles={{ margin: '40px 20px 0px 0px' }}
                    onClick={updatePassword}
                >
                    SAVE
                </Button>
            </Grid>
        </Grid>
    );
}

export default UpdatePasswordForm;
