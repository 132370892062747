import Http from '../../common/utils/Http';

export const cardLoadSearchFrom = async (params) => {
    try {
        let response = await Http.post('pr/funds/search-load-from', params);
        return response;
    } catch (error) {
        throw error;
    }
}

export const cardLoadSearchTo = async (params) => {
    try {
        let response = await Http.post('pr/funds/search-load-to', params);
        return response;
    } catch (error) {
        throw error;
    }
}

export const allocatedCardLoads = async (params) => {
    try {
        let response = await Http.post('pr/funds/search-load-allocated', params);
        return response;
    } catch (error) {
        throw error;
    }
}

export const loadClientData = async (params) => {
    try {
        let response = await Http.post('pr/funds/get-load-client', params);
        return response;
    } catch (error) {
        throw error;
    }
}

export const saveTransfer = async (params) => {
    try {
        let response = await Http.post('pr/funds/save-transfer', params);
        return response;
    } catch (error) {
        throw error;
    }
}

export const viewInvoice = async (transferID) => {
    try {
        let response = await Http.get('pr/funds/view-invoice?invoiceID=' + transferID, {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
}
