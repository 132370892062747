import React, { useEffect } from 'react';
import Title from '../../common/components/base/title/index';
import { makeStyles } from '@mui/styles';
import { Box, Container, Grid, Paper, Typography, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Loader from '../../common/components/base/loader/Loader';
import themeConstants from '../../common/constants/Theme';
import { doSelectAccount, getAccounts } from '../thunk';
import { useDispatch, useSelector } from 'react-redux';
import { upperCase } from 'lodash';
import { dashboardUserHeader } from '../../common/components/Header/thunk';
import ReduxStatus from '../../common/constants/ReduxStatus';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
        fontSize: '14px'
    },
    squaredPaper: {
        paddingTop: '50%',
        position: 'relative !important',
        boxShadow:
            '0px 8px 9px -5px rgb(0 0 0 / 13%), 0px 15px 22px 2px rgb(0 0 0 / 7%), 0px 6px 28px 5px rgb(0 0 0 / 5%) !important',
    },
    squaredBox: {
        background: themeConstants.SECONDARY_DARK,
        "&:hover": {
            opacity: 0.7
        },
        position: 'absolute',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        border: 0,
        cursor: 'pointer',
        fontSize: 14,
        opacity: 1
    },
    avatar: {
        justifyContent: 'center',
        display: "flex"
    }
}));



const SelectProfile = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const data = useSelector(state => state.login.impersonateData);
    const statusProfile = useSelector(state => state.login.statusProfile);
    const statusHeader = useSelector(state => state.header.status);

    useEffect(() => {
        dispatch(getAccounts())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClick = (account) => {
        dispatch(doSelectAccount({ "accountNumber": account }))
            .then((response) => {
                if (response.type === "login/do_select_profile/fulfilled") {
                    dispatch(dashboardUserHeader()).then((response) => {
                        if (response.type === "header/user_header/fulfilled") {
                            navigate("/dashboard");
                        }
                    })


                }
            })
    }

    function stringToColor(string) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${upperCase(name.split(' ')[0][0])}${upperCase(name.split(' ')[1][0])}`,
        };
    }

    return (
        <div className={classes.mainbg}>
            {statusHeader === ReduxStatus.loading && <Loader open={true} />}
            <Container maxWidth='md'>
                <Title title='Select a profile to use' subtitle='Your username is currently linked to multiple accounts. Please select the account you wish to use. Any actions executed will be logged against your username.' />
                <Typography color='primary.light' variant='h5' fontFamily='BentonSans_Medium' fontSize={20} fontWeight={400}>Choose an account</Typography><br />
                <Grid container spacing={6}>
                    {data && data.map((data, idx) => (
                        <Grid item xs={4} key={data.ACCOUNTNUMBER}>
                            <Paper className={classes.squaredPaper}>
                                <Box component='button' className={classes.squaredBox} onClick={() => handleClick(data.ACCOUNTNUMBER)}>
                                    <div>
                                        <div className={classes.avatar}>
                                            <Avatar  {...stringAvatar(data.ACCOUNTFULLNAME)} /></div>
                                        <Typography color='secondary.light' variant='h6' fontFamily='BentonSans_Regular' fontSize={20} fontWeight={400}>
                                            {data.ACCOUNTFULLNAME.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())}
                                        </Typography>
                                    </div>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}

                </Grid>
            </Container>
        </div>
    );
};

export default SelectProfile;
