import React, { useEffect } from 'react';
import { givePayCard } from './thunk';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../common/components/base/title/Title';
import { Typography, Container, } from '@mui/material';
import ReactHtmlParser from 'html-react-parser';

const GivePayCard = () => {
    const givedata = useSelector(state => state.givePayCard.data.content);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(givePayCard());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Title title='Read More' subtitle='Read more about the PayCard product and how it works'></Title>
            <Container maxWidth='lg'>
                {
                    givedata && givedata.map((givedata, idx) => (
                        <div key={givedata.CONTENTID}>
                            <Typography color='primary.light' variant='h6' fontFamily='BentonSans_Regular' fontSize={20} fontWeight={400}>{givedata.CONTENTHEADER}</Typography>
                            <Typography color='secondary.dark' variant='subtitle2' fontFamily='BentonSans_Regular'>{ReactHtmlParser(givedata.CONTENTDETAIL)}</Typography><br /><br />
                        </div>
                    ))
                }
            </Container>
        </div>
    );
};

export default GivePayCard;
