import { useLocation } from 'react-router-dom';

const CheckPathnameHome = () => {
    const location = useLocation();
    let path = location.pathname;
    if (
        path === '/' ||
        path === '/conditions' ||
        path === '/disclaimer' ||
        path === '/get' ||
        path === '/give' ||
        path === '/privacy-statement'
    ) {
        return 500;
    } else {
        return 100;
    }
};

const CheckPathnameDashboard = () => {
    const location = useLocation();
    let path = location.pathname;
    let pathOutside = location.pathname.split('d/')[0] + 'd';
    if (path === '/dashboard' || pathOutside === '/dashboard') {
        return 500;
    } else {
        return 100;
    }
};

export { CheckPathnameHome, CheckPathnameDashboard };
