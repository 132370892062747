import React from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@mui/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import { dateFormating } from '../../common/utils/helper';
import ReactHtmlParser from 'html-react-parser';
import NewsIcon from './NewsIcon';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
    },
    newsTable: {
        backgroundColor: theme.palette.secondary.light
    },
    iconTableCell: {
        width: '50px',
        padding: '10px !important',
        verticalAlign: 'initial !important'
    },
    titleTableCall: {
        width: '250px',
        padding: '10px !important',
        color: 'primary.light !important',
        verticalAlign: 'initial !important',
        fontFamily: 'BentonSans_Regular !important'
    },
    bodyTableCell: {
        width: '700px',
        padding: '10px !important',
        color: 'secondary.dark !important',
        verticalAlign: 'initial !important',
        fontFamily: 'BentonSans_Regular !important'
    },
    paragraphTableCell: {
        fontFamily: 'BentonSans_Regular !important'
    },
    onlineFromTableCell: {
        width: '200px',
        padding: '10px !important',
        color: 'primary.light !important',
        verticalAlign: 'initial !important',
        fontFamily: 'BentonSans_Regular !important'
    },
    shouldHighLight: {
        backgroundColor: theme.palette.secondary.lightgrey
    }
}));

const NewsBaseTable = (props) => {
    const { rows, messageID } = props;
    const indexOfNoticeToHide = rows.findIndex((item => item.NEWSMESSAGEID === messageID));
    const classes = useStyles();

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" className={classes.newsTable}>
                    <TableBody>
                        {rows && rows.length > 0 && rows.map((row, index) => {
                            return (
                                <TableRow hover tabIndex={-1} key={index} className={indexOfNoticeToHide === index ? classes.shouldHighLight : classes.newsTable}>
                                    <TableCell className={classes.iconTableCell}>
                                        <NewsIcon iconType={row.NEWSMESSAGETYPE} />
                                    </TableCell>
                                    <TableCell className={classes.titleTableCall}>
                                        {row.TITLE}
                                    </TableCell>
                                    <TableCell className={classes.bodyTableCell}>
                                        <Typography align='justify' variant='subtitle2' className={classes.paragraphTableCell}>
                                            {ReactHtmlParser(row.BODY)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.onlineFromTableCell}>
                                        {dateFormating(row.DATEONLINE)}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    );
};

export default NewsBaseTable;
