import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Select,
    MenuItem,
    Box,
    Alert,
    FormHelperText,
} from '@mui/material';
import * as validation from '../validation/orderCardValidation';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../common/components/base/input/Input';
import Button from '../../../../common/components/base/button/Button';
import CheckRadio from '../../../../common/components/base/checkradio/index';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency } from '../../../../common/utils/helper';
import ReduxStatus from '../../../../common/constants/ReduxStatus';
import { fetchUser, fetchBankBranches } from '../../redux/thunk';
import { calculateCosts } from '../utils/utils';
import { saveCardOrder } from '../../redux/slice';
import Loader from './../../../../common/components/base/loader/Loader';

export default function OrderCardForm() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.orderCard.user.data);
    const userStatus = useSelector((state) => state.orderCard.user.status);
    const bankBranches = useSelector((state) => state.orderCard.bankBranches.data);
    const dispatch = useDispatch();

    const [stateValues, setStateValues] = useState({
        totalDeliveryCost: 0,
        totalCardCost: 0,
        totalCost: 0,
        selectedDeliveryMethod: '1',
        bankBranchCode: '',
    });

    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        dispatch(fetchUser());
        dispatch(fetchBankBranches());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setStateValues({
            ...stateValues,
            cardOrderQuantity: user.CARDORDERQUANTITY,
            cardOrderUUID: user.CARDORDERUUID,
            name: user.NAME,
            idNumber: user.IDNUMBER,
            contactNumber: user.CONTACTNUMBER,
            altContactNumber: user.ALTCONTACTNUMBER,
            deliveryMethod: user.CARDORDERDELIVERYMETHOD,
            building: user.BUILDING,
            street: user.STREET,
            city: user.CITY,
            code: user.CODE,
            clientFaceDeliveryCostTier1: user.CLIENTFACEDELIVERYCOSTTIER1,
            clientFaceDeliveryCostTier2: user.CLIENTFACEDELIVERYCOSTTIER2,
            clientFaceDeliveryCostTier3: user.CLIENTFACEDELIVERYCOSTTIER3,
            clientAllowsBrancheDelivery: user.CLIENTALLOWSBRANCHDELIVERY,
            brancheDeliveryCost: user.CLIENTBRANCHDELIVERYCOST,
            cardUnitPrice: user.CLIENTCARDUNITCOST,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const costs = calculateCosts(
            +stateValues.cardOrderQuantity,
            +stateValues.clientFaceDeliveryCostTier1,
            +stateValues.clientFaceDeliveryCostTier2,
            +stateValues.clientFaceDeliveryCostTier3,
            +stateValues.brancheDeliveryCost,
            +stateValues.selectedDeliveryMethod,
            +stateValues.cardUnitPrice
        );
        setStateValues({
            ...stateValues,
            totalDeliveryCost: costs.totalDeliveryCost,
            totalCardCost: costs.totalCardCost,
            totalCost: costs.totalCost,
            clientFaceDeliveryCost: costs.clientFaceDeliveryCost,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateValues.cardOrderQuantity]);

    const handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setStateValues({ ...stateValues, [name]: value });
    };

    const handleSelect = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setStateValues({ ...stateValues, [name]: value });

        if (!validation.isBankBrancheError(value)) {
            setValidationErrors({
                ...validationErrors,
                bankBrancheError: '',
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let errorCount = 0;

        if (validation.isNumberOfCardsError(stateValues.cardOrderQuantity)) {
            errors = { ...errors, numberOfCardsError: 'Please choose one or more cards.' };
            errorCount++;
        }

        if (validation.isNameError(stateValues.name)) {
            errors = { ...errors, nameError: 'Please fill in a valid name.' };
            errorCount++;
        }

        if (validation.isIdError(stateValues.idNumber)) {
            errors = { ...errors, idError: 'Please fill in a valid ID.' };
            errorCount++;
        }

        if (validation.isContactNumberError(stateValues.contactNumber)) {
            errors = { ...errors, contactNumberError: 'Please fill in a valid contact number.' };
            errorCount++;
        }

        if (validation.isAltContactNumberError(stateValues.altContactNumber)) {
            errors = { ...errors, altContactNumberError: 'Please fill in a valid contact number.' };
            errorCount++;
        }

        if (validation.isBuildingError(stateValues.building)) {
            errors = { ...errors, addressError: 'Please fill in a valid building name.' };
            errorCount++;
        }

        if (validation.isStreetError(stateValues.street)) {
            errors = { ...errors, addressLine2Error: 'Please fill in a valid adress.' };
            errorCount++;
        }

        if (validation.isCityError(stateValues.city)) {
            errors = { ...errors, cityError: 'Please fill in a valid city name.' };
            errorCount++;
        }

        if (validation.isCodeError(stateValues.code)) {
            errors = { ...errors, codeError: 'Please fill in a valid code.' };
            errorCount++;
        }

        if (stateValues.selectedDeliveryMethod === '0' && stateValues.clientAllowsBrancheDelivery === 1) {
            if (validation.isBankBrancheError(stateValues.bankBranchCode)) {
                errors = { ...errors, bankBrancheError: 'Please choose a bankbranche.' };
                errorCount++;
            }
        }
        setValidationErrors(errors);

        if (errorCount === 0) {
            dispatch(saveCardOrder(stateValues));

            const queryString =
                `cardOrderUUID=${stateValues.cardOrderUUID}&cardOrderQuantity=${stateValues.cardOrderQuantity}&` +
                `cardUnitPrice=${stateValues.cardUnitPrice}&totalCardCost=${stateValues.totalCardCost}&clientFaceDeliveryCost=${stateValues.clientFaceDeliveryCost}&` +
                `totalDeliveryCost=${stateValues.totalDeliveryCost}&totalCost=${stateValues.totalCost}&name=${stateValues.name}&` +
                `idNumber=${stateValues.idNumber}&contactNumber=${stateValues.contactNumber}&altContactNumber=${stateValues.altContactNumber}&` +
                `selectedDeliveryMethod=${stateValues.selectedDeliveryMethod}&building=${stateValues.building}&street=${stateValues.street}&` +
                `city=${stateValues.city}&code=${stateValues.code}&branchCode=${stateValues.bankBranchCode}&bankBrancheName=${stateValues.bankBrancheName}`;

            navigate('/dashboard/order-card-confirm?' + queryString);
        }
    };

    return (
        <Grid container spacing={3}>
            {userStatus === ReduxStatus.loading && <Loader open={true} />}
            {userStatus === ReduxStatus.loadFailed && (
                <Grid item xs={12}>
                    <Alert severity='error' sx={{ mt: 5 }}>
                        Could not load the user data...
                    </Alert>
                </Grid>
            )}
            {userStatus === ReduxStatus.loaded && (
                <>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 4, mb: 2 }}>
                            <Typography
                                variant='h6'
                                component='h4'
                                color='primary.subtitle'
                                fontFamily='BentonSans_Book'
                            >
                                *Required fields
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='h4' component='h3' color='primary.light'>
                                1. CARD ORDER COST
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Input
                            inputLabel='Number of cards*'
                            name='cardOrderQuantity'
                            value={stateValues.cardOrderQuantity}
                            onChange={(e) => handleUserInput(e)}
                            variant='outlined'
                            type='number'
                            min='1'
                            fullWidth
                            required
                            error={validationErrors.numberOfCardsError ? true : false}
                            helperText={validationErrors.numberOfCardsError}
                        />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Box sx={{ mt: 6 }}>
                            <Typography color='primary.subtitle' variant='inputField'>
                                Total cost of cards at: {formatCurrency(+stateValues.cardUnitPrice)} per card:{' '}
                                {formatCurrency(+stateValues.totalCardCost)}
                                <br></br>
                                Cost including: {formatCurrency(+stateValues.totalDeliveryCost)} delivery fee:{' '}
                                {formatCurrency(+stateValues.totalCost)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <Box sx={{ mb: 4, mt: 3 }}>
                            <Typography variant='h4' component='h3' color='primary.light'>
                                2. DELIVERY INFORMATION
                            </Typography>
                        </Box>
                        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
                            <Input
                                inputLabel='Receiver name*'
                                name='name'
                                data-testid='receiverNameInput'
                                value={stateValues.name}
                                onChange={(e) => handleUserInput(e)}
                                type='text'
                                fullWidth
                                required
                                error={validationErrors.nameError ? true : false}
                                helperText={validationErrors.nameError}
                            />

                            <Input
                                inputLabel='Receiver id number*'
                                name='idNumber'
                                value={stateValues.idNumber}
                                onChange={(e) => handleUserInput(e)}
                                type='text'
                                fullWidth
                                required
                                error={validationErrors.idError ? true : false}
                                helperText={validationErrors.idError}
                            />
                            <Input
                                inputLabel='Contact number*'
                                name='contactNumber'
                                value={stateValues.contactNumber}
                                onChange={(e) => handleUserInput(e)}
                                type='text'
                                fullWidth
                                required
                                error={validationErrors.contactNumberError ? true : false}
                                helperText={validationErrors.contactNumberError}
                            />
                            <Input
                                inputLabel='Alternative contact number'
                                name='altContactNumber'
                                value={stateValues.altContactNumber}
                                onChange={(e) => handleUserInput(e)}
                                type='text'
                                fullWidth
                                error={validationErrors.altContactNumberError ? true : false}
                                helperText={validationErrors.altContactNumberError}
                            />

                            <div>
                                <FormControl sx={{ mt: 3, mb: 1 }} variant='standard'>
                                    <FormLabel
                                        color='secondary'
                                        id='delivery-method-group-label'
                                        sx={{
                                            color: 'primary.subtitle',
                                        }}
                                    >
                                        <Typography variant='inputField'>DELIVERY METHOD*</Typography>
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        required
                                        color='secondary'
                                        aria-labelledby='delivery-method-group-label'
                                        name='selectedDeliveryMethod'
                                        value={stateValues.selectedDeliveryMethod}
                                        onChange={(e) => handleUserInput(e)}
                                    >
                                        <FormControlLabel
                                            value='1'
                                            control={<CheckRadio color='secondary' />}
                                            label='Courier'
                                        />
                                        {stateValues.clientAllowsBrancheDelivery === 1 && (
                                            <FormControlLabel
                                                value='0'
                                                control={<CheckRadio color='secondary' />}
                                                label='Bank Branches'
                                            />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                                {stateValues.selectedDeliveryMethod === '0' &&
                                    stateValues.clientAllowsBrancheDelivery === 1 && (
                                        <FormControl sx={{ mt: 6, width: '400px' }}>
                                            {bankBranches && (
                                                <>
                                                    <Select
                                                        displayEmpty
                                                        size='small'
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        id='bankBranchCode'
                                                        name='bankBranchCode'
                                                        value={stateValues.bankBranchCode}
                                                        onChange={(e) => handleSelect(e)}
                                                        sx={{ backgroundColor: '#FFF', color: 'secondary' }}
                                                        error={validationErrors.bankBrancheError ? true : false}
                                                    >
                                                        <MenuItem disabled value=''>
                                                            Select a bankbranche*
                                                        </MenuItem>
                                                        {bankBranches.map((branche) => (
                                                            <MenuItem
                                                                key={`${branche.BANKBRANCHCODE}-${branche.BANKBRANCHNAME}`}
                                                                value={branche.BANKBRANCHCODE}
                                                            >
                                                                {`${branche.BANKBRANCHCODE} | ${branche.BANKBRANCHNAME}`}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText sx={{ color: 'red' }}>
                                                        {validationErrors.bankBrancheError}
                                                    </FormHelperText>
                                                </>
                                            )}
                                        </FormControl>
                                    )}
                            </div>

                            {stateValues.selectedDeliveryMethod === '1' && (
                                <>
                                    <Input
                                        inputLabel='Delivery Address*'
                                        placeholder='Building'
                                        name='building'
                                        value={stateValues.building}
                                        onChange={(e) => handleUserInput(e)}
                                        type='text'
                                        fullWidth
                                        error={validationErrors.addressError ? true : false}
                                        helperText={validationErrors.addressError}
                                    />
                                    <Input
                                        name='street'
                                        placeholder='Street'
                                        value={stateValues.street}
                                        onChange={(e) => handleUserInput(e)}
                                        type='text'
                                        fullWidth
                                        error={validationErrors.addressLine2Error ? true : false}
                                        helperText={validationErrors.addressLine2Error}
                                    />
                                    <Input
                                        inputLabel='City*'
                                        name='city'
                                        value={stateValues.city}
                                        onChange={(e) => handleUserInput(e)}
                                        type='text'
                                        fullWidth
                                        required
                                        error={validationErrors.cityError ? true : false}
                                        helperText={validationErrors.cityError}
                                    />
                                    <Input
                                        inputLabel='Code*'
                                        name='code'
                                        value={stateValues.code}
                                        onChange={(e) => handleUserInput(e)}
                                        type='text'
                                        fullWidth
                                        required
                                        error={validationErrors.codeError ? true : false}
                                        helperText={validationErrors.codeError}
                                    />
                                </>
                            )}
                            <Button
                                type='submit'
                                size='medium'
                                variant='contained'
                                styles={{ margin: '40px 20px 0px 0px' }}
                            >
                                Submit card order
                            </Button>
                        </form>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
