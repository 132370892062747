import React, { useEffect } from 'react';
import { getContacts } from './thunk';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../common/components/base/title/Title';
import { Typography, Container } from '@mui/material';
import ReactHtmlParser from 'html-react-parser';

const ContactUs = () => {
    const contacts = useSelector(state => state.contact.data.content);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContacts());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Title title='Contact Us' subtitle='How to contact us with questions or queries'></Title>
            <Container maxWidth='lg'>
                {
                    contacts && contacts.map((contact, idx) => (
                        <div key={idx}>
                            <Typography color='secondary.dark' variant='subtitle2' fontFamily='BentonSans_Regular'>{ReactHtmlParser(contact.CONTENTDETAIL)}</Typography><br />
                        </div>
                    ))
                }

            </Container>
        </div>
    );
};

export default ContactUs;

