import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInvoices, getCardLoadPdf, getCardOrderPdf } from './service';
import fileDownload from 'js-file-download';

export const searchInvoices = createAsyncThunk(
    'downloadPreviousInvoice/search_invoices',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getInvoices(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const fetchCardLoadPdf = createAsyncThunk(
    'downloadPreviousInvoice/fetch_card_load_pdf',
    async (params, { rejectWithValue }) => {
        try {
            const pdfResponse = await getCardLoadPdf(params);
            fileDownload(pdfResponse.data, `invoice_${params.invoiceID}.pdf`)
            return true;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const fetchCardOrderPdf = createAsyncThunk(
    'downloadPreviousInvoice/fetch_card_order_pdf',
    async (params, { rejectWithValue }) => {
        try {
            const pdfResponse = await getCardOrderPdf(params);
            fileDownload(pdfResponse.data, `invoice_${params.invoiceID}.pdf`)
            return true;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
