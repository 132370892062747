const ReduxStatus = {
    empty: '',
    loading: 'loading',
    loaded: 'loaded',
    loadFailed: 'loadFailed',
    saving: 'saving',
    saved: 'saved',
    saveFailed: 'saveFailed',
    editing: 'editing',
    edited: 'edited',
    editFailed: 'editFailed',
    deleting: 'deleting',
    deleted: 'deleted',
    deleteFailed: 'deleteFailed',
}

export default ReduxStatus;
