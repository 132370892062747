import React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import themeConstants from '../../../constants/Theme';

export default function ThemeProvider({ theme, ...props }) {
    return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}

export const theme = createTheme({
    components:{
        MuiButtonBase: {
            defaultProps:{
                disableRipple: true
            }
        }
    },
    palette: {
        primary: {
            main: themeConstants.BRAND_COLOR_PRIMARY,
            light: themeConstants.PRIMARY_LIGHT,
            dark: themeConstants.PRIMARY_DARK,
            subtitle: themeConstants.TITLE_COLOR,
            black: themeConstants.BLACK,
        },
        secondary: {
            main: themeConstants.BRAND_COLOR_SECONDARY,
            light: themeConstants.SECONDARY_LIGHT,
            dark: themeConstants.SECONDARY_DARK,
            grey: themeConstants.GREY,
            lightgrey: themeConstants.LIGHTGREY,
            header:themeConstants.HEADER

        },
        background: {
            paper: themeConstants.BACKGROUND_COLOR,
        },
        action: {
            disabledBackground: themeConstants.BRAND_COLOR_SECONDARY,
            disabled: '#ffffff'
        },
    },
    typography: {
        fontFamily: ['BentonSans_Regular'].join(','),
        h4: {
            fontSize: 28,
            fontWeight: 300,
            fontFamily: 'BentonSans_Regular',
        },
        inputField: {
            fontFamily: 'BentonSans_Regular',
            fontSize: '11px',
            fontWeight: '400',
            letterSpacing: '1px',
            textTransform: 'uppercase',
        },
        fontSize: 15
    }
});

